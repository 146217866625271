<template>
  <div class="maintenanceLogList-wrapper">
    <list
      ref="list"
      exportPermission="maintExport"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="生成本年维保计划" permission="generateMaint" @click="generateMaintenancePlan"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="设备名称" v-model="searchParams.name"></v-input>
        <v-input label="设备编号" v-model="searchParams.serialNum"></v-input>
        <v-datepicker label="计划完成时间段" defaultTimeType="week" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-select3  
          v-model="searchParams.communityIds"
          label="所属项目"
          :communityParams="communityParams"
        ></v-select3>
        <v-select label="维保状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select label="执行方式" v-model="searchParams.type" :options="typeOps"></v-select>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-input label="负责人" v-model="searchParams.chargerUserName"></v-input>
        <v-input label="执行人" v-model="searchParams.executorName"></v-input>
        <v-input label="所属组团" v-model="searchParams.area"></v-input>
        <v-datepicker label="维保完成时间段" :maxDate="maxDate" :startTime.sync="searchParams.fromDate" :endTime.sync="searchParams.toDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="detail" @click="check(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, generateMaintenanceURL } from './api'
import { createHTMLVNode } from 'common/vdom'
import { communityParams, tenantParams } from 'common/select2Params'
export default {
  name: 'MaintenanceLogList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      maxDate: new Date(),
      communityParams,
      tenantParams,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '待维保',
          value: 2
        }, {
          text: '已维保',
          value: 4
        }, {
          text: '漏维保',
          value: 7
        }, {
          text: '超时未维保',
          value: 1
        }, {
          text: '超时已维保',
          value: 6
        }
      ],
      typeOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '蓝牙',
          value: 1
        }, {
          text: '非蓝牙',
          value: 2
        }, {
          text: '二维码',
          value: 3
        }
      ],
      searchParams: {
        name: '',
        serialNum: '',
        communityIds: '',
        status: undefined,
        type: undefined,
        regionId: '',
        chargerUserName: '',
        executorName: '',
        area: '',
        startDate: '',
        endDate: '',
        fromDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'name',
          label: '设备名称'
        },
        {
          prop: 'serialNum',
          label: '设备编号'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'area',
          label: '所属组团'
        },
        {
          prop: 'statusShowName',
          label: '维保状态'
        },
        {
          prop: 'finishTypeShowName',
          label: '执行方式'
        },
        {
          prop: 'chargerUserName',
          label: '负责人',
          formatter: (row, prop) => {
            let name = row.chargerUserName ? row.chargerUserName : ''
            let mobile = row.chargerUserMobile ? row.chargerUserMobile : ''
            return createHTMLVNode(this, name ? `${name}<br/>${mobile}` : '')
          }
        },
        {
          prop: 'executorName',
          label: '执行人',
          formatter: (row, prop) => {
            let name = row.executorName ? row.executorName : ''
            let mobile = row.executorMobile ? row.executorMobile : ''
            return createHTMLVNode(this, name ? `${name}<br/>${mobile}` : '')
          }
        },
        {
          prop: 'maintDate',
          label: '维保日期'
        },
        {
          prop: 'finishTime',
          label: '维保完成时间'
        }
      ]
    }
  },
  methods: {
    async generateMaintenancePlan () {
      let isOk = await this.$confirm('该操作将生成大量维保日志数据，在晚上8点~次日7点进行操作,是否确定继续操作？')
      isOk && this.generateMaintenance()
    },
    generateMaintenance () {
      let _this = this
      _this.$axios.post(generateMaintenanceURL, { dataObject: '生成本年维保计划' }).then(res => {
        let status = String(res.status)
        if (status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        } 
      })
    },
    check (row) {
      this.$router.push({
        name: 'maintenanceLogForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
